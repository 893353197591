import React from "react";
import { graphql, useStaticQuery } from "gatsby";

// Components
import SecondaryLanding from "../../../../components/layouts/secondary-landing";
import HeroChevron from "../../../../components/hero/hero-chevron";
import SEO from "../../../../components/seo/seo";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import Button from "../../../../components/custom-widgets/button";
import FaqAccordion from "../../../../components/faq/faq-accordion";

// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Helpers
import getSortedFaqDataByCategory from "../../../../helpers/getSortedFaqDataByCategory";
import getHeroImgVariables from "../../../../helpers/getHeroImgVariables";

const SpanishClosingCostOffer = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/closing-costs/hero-closing-costs-03132023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "closing-cost-hero",
    ...getHeroImgVariables(imgData),
    altText: "Young couple moving in new house, holding keys",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Ahorre en costos de cierre"
          }
        },
        {
          id: 2,
          button: {
            id: "closing-cost-offer-hero-cta-1",
            text: "Encuentra un Oficial de Préstamo",
            url: "/es/banca-personal/contacte-a-un-asesor-hipotecario",
            class: "btn-white"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca Personal"
    },
    {
      id: 2,
      url: "/es/banca-personal/prestamos-hipotecarios",
      title: "Préstamos Hipotecarios"
    },
    {
      id: 3,
      active: true,
      title: "Ahorre en Costos de Cierre"
    }
  ];

  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Spanish Closing Costs Offer"]);

  const seoData = {
    title: "Ahorre en costos de cierre",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Ahorre en costos de cierre"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Descubra cómo puede ahorrar hasta $600 en costos de cierre de su hipoteca con la oferta de cuenta corriente de WaFd Bank para clientes nuevos y existentes."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/prestamos-hipotecarios/ahorre-en-costos-de-cierre"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-closing-costs-03132023.jpg"
      }
    ],
    schema
  };

  const ctaButtonData = {
    id: "open-acount-cta",
    type: "link",
    containerClass: "my-3",
    class: "btn-primary",
    text: "Abra una cuenta en línea",
    showIcon: false,
    url: "/es/banca-personal/cuenta-de-cheques"
  };

  const faqAccordionData = {
    id: "spanish-closing-costs-offer-faq-section",
    isSpanish: true,
    title: "Preguntas Frecuentes",
    // pass faqData instead of faqCategoryNames
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container">
        <h1>Ahorre hasta $600 en costos de cierre con una cuenta corriente de WaFd Bank*</h1>
        <ul>
          <li>
            Ahorre $200 si realiza los pagos de su hipoteca desde una cuenta corriente de WaFd Bank (con depósito
            directo).
          </li>
          <li>
            Ahorre otros $400 cuando se registre para el servicio EZ-Pay, retirando automáticamente el pago de la
            hipoteca de su cuenta corriente en WaFd o en cualquier otro banco.
          </li>
        </ul>
        <div className="row align-items-center my-5">
          <div className="col-auto text-center bg-light rounded-circle mx-lg-4 px-3 py-4 mx-auto d-flex-center align-items-center">
            <FontAwesomeIcon className="fa-3x" icon={["fal", "money-bill-wave"]} />
          </div>
          <div className="col-sm-9">
            <h2>Tres sencillos pasos para reducir los costos de cierre:</h2>
            <ol>
              <li>Elija la cuenta corriente que mejor se adapte a sus necesidades.</li>
              <Button {...ctaButtonData} />
              <li>Configure el depósito directo en su cuenta de WaFd Bank.</li>
              <li>
                Complete el formulario de registro de EZ-Pay para configurar pagos automáticos de su hipoteca desde su
                cuenta de WaFd Bank.
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section className="bg-success">
        <div className="container text-center">
          <h3 className="text-white mb-0">¡Hasta $600 deducidos de sus costos de cierre!</h3>
        </div>
      </section>

      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default SpanishClosingCostOffer;
